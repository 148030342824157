import axios from "axios";
import store from "@/store/index";
import { Notification, MessageBox } from "element-ui";
import { getToken } from '@/utils/storage';
import { message } from '@/utils/resetMessage';
import { setToken } from "./storage";

axios.defaults.baseURL = window.baseUrl
axios.defaults.timeout = 10000
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;
axios.defaults.retry = 3; //重试次数
axios.defaults.retryDelay = 1000; //重试间隔

//对外接口
export function request({ url, params, method,timeout }) {
  if (!method) {
    method = 'post'
  }
  if(!timeout){
    timeout = 10000
  }
  if (method === 'get') {
    return get(url, params)
  } else if (method === 'post') {
    return post(url, params,timeout)
  }
}

//封装get方法
function get(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, params).then(res => {
      if (res.code == constants.interfaceResult.success) {
        resolve(res.data);
      } else {
        if (getToken() && res.msg !== '') {
          message.error({
            message: res.msg,
            showClose: true,
            duration: 4000,
            offset: 100
          });
        }
        reject(res.msg);
      }
    }).catch(err => {
        reject(err.msg)
      })
  })
}

//封装post方法
function post(url, params,timeout) {
  return new Promise((resolve, reject) => {
    axios.post(url, params,{timeout:timeout}).then(res => {
      if (res.code == constants.interfaceResult.success) {
        resolve(res.data)
      } else {
        if (getToken() && res.msg !== '') {
          message.error({
            message: res.msg,
            showClose: true,
            duration: 4000,
            offset: 100
          });
        }
        reject(res.msg);
      }
    }).catch(err => {

        reject(err);
    })
  })
}

// request拦截器
axios.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers["Authorization"] = getToken(); // 让每个请求携带自定义token
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// response 拦截器
axios.interceptors.response.use(
  response => {
    // 接收后台参数状态
    if(response.status == 200 && response.data.code == 0) {
      return response.data;
    }else {
      let res = response.data;
      // 这里可以设置后台返回状态码是500或者是其他,然后重定向跳转
      if(res.code == 400 || res.code == 401){
        setToken(null)
        window.globalRouter.push({ path: "/login" });
      }
      message.error({
        message: res.msg,
        duration: 4000,
        offset: 80
      });
      return Promise.reject(
          new Error(res.msg || (res.error &&res.error.message) || '未知错误')
      );
    }
  },
  error => {
    console.log('服务器错误信息:', error);
      if(error.response) {
          // error.response有错误信息,是接口错误,不是取消请求
          // 获取错误码,弹出提示信息,reject()
          let code = error.response.status;
          switch(code){
            case 400:
            case 401:
              setToken(null)
              window.globalRouter.push({ path: "/login" });
              return Promise.reject(error.message);
              break;
            case 403:
              MessageBox.confirm("登录状态已过期，您可以继续留在该页面，或者重新登录","系统提示",{
                confirmButtonText: "重新登录",
                cancelButtonText: "取消",
                type: "warning"
              }).then(() => {
                setToken(null)
                window.globalRouter.push({ path: "/login" });
              });
              return Promise.reject(error.message);
              break;
            case 301: error.message = '请求的数据具有新的位置且更改是永久的'; break
            case 302: error.message = '请求的数据临时具有不同 URI'; break
            case 304: error.message = '未按预期修改文档'; break
            case 305: error.message = '必须通过代理来访问请求的资源'; break
            case 402: error.message = '所使用的模块需要付费使用'; break
            case 404: error.message = '服务器找不到给定的资源'; break
            case 407: error.message = '客户机首先必须使用代理认证自身'; break
            case 415: error.message = '请求类型不支持，服务器拒绝服务'; break
            case 417: error.message = '未绑定登录账号，请使用密码登录后绑定'; break
            case 426: error.message = '用户名不存在或密码错误'; break
            case 429: error.message = '请求过于频繁'; break
            case 500: error.message = '服务器内部错误，无法完成请求'; break
            case 501: error.message = '服务不支持请求'; break
            case 502: error.message = '网络错误，服务器接收到上上游服务器无效响应'; break
            case 503: error.message = '服务器无法处理请求'; break
            case 504: error.message = '网络请求超时'; break
            case 999: error.message = '系统未知错误，请反馈给管理员'; break              
          }
          message.error({
            message: error.message,
            duration: 4000,
            offset: 80
          });
          return Promise.reject(error.message);
      }else {
          // 是取消请求
          // 直接reject
          return Promise.reject(error.message);
      }
  }
);
